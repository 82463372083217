:root {
  --white: #ffffff;
  --black: #000000;
  --black-50: rgba(0, 0, 0, 0.5);

  --gray-light: #f5f5f5;
  --gray: #dcdcdc;
  --gray-medium-light: #dadada;
  --gray-medium: #c0c0c0;
  --gray-dark: #696969;
}
a {
  color: #696969;
  color: var(--gray-dark);
  transition: color 0.3s;
  text-decoration: none;
}
a:hover {
  color: #000000;
  color: var(--black);
  text-decoration: underline;
}
figure {
  margin: 0;
}
.icon-link * {
    vertical-align: middle;
  }
img.dummy {
  opacity: 0;
}
.dummy-item .title {
    text-align: center;
  }
.read-more #toggle {
    display: none;
  }
.read-more .full {
    display: none;
  }
.read-more .less {
    display: none;
  }
.read-more #toggle:checked ~ .wrap,
  .read-more #toggle:checked ~ .more {
    display: none;
  }
.read-more #toggle:checked ~ .full {
    display: block;
  }
.read-more #toggle:checked ~ .less {
    display: inline-block;
  }
.read-more label {
    color: #ffffff;
    color: var(--white);
    padding: 8px 8px 6px;
    margin: 0 0 15px;
    border-radius: 3px;
    display: inline-block;
    background: #000000;
    background: var(--black);
  }
@font-face {
  font-family: 'Lubalin';
  font-style: normal;
  font-weight: 100;
  src: url(/assets/front/fonts/2eaadafe.woff2) format('woff2'),
    url(/assets/front/fonts/2a065692.woff) format('woff');
}
@font-face {
  font-family: 'OCRB';
  font-style: normal;
  font-weight: 100;
  src: url(/assets/front/fonts/5175a06d.woff2) format('woff2'), url(/assets/front/fonts/b1f20b1b.woff) format('woff');
}
.columns {
  display: flex
}
.columns > * {
    flex: 1;
  }
.contain {
  max-width: 1100px;
  max-width: var(--max-width-large);
  margin: auto;
  box-sizing: border-box;

  padding: 0 30px;
}
.sidebar {
  display: flex;
  justify-content: center
}
.sidebar .info {
    flex: 3;
  }
.sidebar .detail {
    flex: 7;
  }
.sidebar .info {
    padding: 0 40px 40px 0
  }
.sidebar .info img {
      max-width: 160px;
    }
.sidebar .read-more {
    display: none;
  }
.sidebar .info header {
    margin: 0 0 35px
  }
.sidebar .info header .title {
      margin: 0;
    }
.sidebar .info header .header {
      margin: 0;
      font-size: 16px;
      font-weight: 100;
    }
.sidebar .location {
    display: block;
    margin: 0 0 40px
  }
.sidebar .location .title {
      margin: 0;
    }
@media (max-width: 767px) {
  .sidebar {
    flex-direction: column
  }

    .sidebar .info {
      padding: 0 0 15px;
    }

    .sidebar .read-more {
      display: block;
    }

    .sidebar .description-full {
      display: none;
    }
}
@media (max-width: 820px) and (orientation: landscape) {
  .sidebar {
    flex-direction: row
  }

    .sidebar .info {
      padding: 0 25px 15px;
    }

    .sidebar .read-more {
      display: block;
    }

    .sidebar .description-full {
      display: none;
    }
}
ul.thumbs {
  margin: 0;
  padding: 0 0 5px;
  list-style: none;
  display: flex;
  flex-wrap: wrap
}
ul.thumbs img {
    width: 100%;
    display: block;
    transition: opacity 0.3s;
  }
ul.thumbs li {
    flex: 0 0 25%;
    box-sizing: border-box;
    padding: 1px;
  }
ul.thumbs p {
    margin: 5px 0 0;
  }
ul.thumbs a {
    display: block;
    position: relative
  }
ul.thumbs a .icon {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
ul.thumbs a:hover img {
        opacity: 0.3;
      }
ul.thumbs a:hover .icon {
        display: block;
      }
ul.thumbs figure {
    width: 100%;
    height: 100%;
    background: #000000;
    background: var(--black);

    position: relative
  }
ul.thumbs figure figcaption {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #000000;
      background: var(--black);
      color: #ffffff;
      color: var(--white);
      padding: 0 10px 3px
    }
ul.thumbs figure figcaption .title {
        font-size: 13px;
      }
@media (max-width: 767px) {
  ul.thumbs li {
    flex: 0 0 50%;
  }
}
@media (max-width: 820px) and (orientation: landscape) {
  ul.thumbs li {
    flex: 0 0 33.3%;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
h1 {
  font-size: 42px;
  margin: 0.7em 0;
  font-weight: 100;
}
p {
  font-size: 13px;
  line-height: 1.4;
}
:root {
  --font-serif: 'Lubalin', serif;
  --font-sans: 'OCRB', system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;

  --header-height: 80px;
  --max-width-large: 1100px;
}
.work {
  cursor: pointer;
  position: relative
}
.work img {
    transition: opacity 0.3s;
  }
.work .maximize {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    background: var(--white);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
.work:hover img {
      opacity: 0.7;
    }
.work:hover .maximize {
      display: flex;
    }
.work figcaption {
  margin-top: 5px;
  display: flex;
  align-items: baseline
}
.work figcaption .title {
    margin: 0 5px 0 0;
  }
.work figcaption p {
    margin: 3px 0 0;
  }
.v-dialog,
  .v-dialog .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
  }
.v-dialog button.close {
    z-index: 10000;
  }
.v-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
  }
.v-dialog .overlay {
    background: rgba(0, 0, 0, 0.5);
    background: var(--black-50);
  }
.v-dialog .modal {
    position: relative;
    flex: 0 0 auto;
    background: #000000;
    background: var(--black);
    margin: 6rem 0 0;
    z-index: 11000;
    border-radius: 4px;
    width: 45vw
  }
.v-dialog .modal img {
      width: 100%;
    }
.v-dialog .modal figcaption {
      margin: 10px 0 0;
      display: flex
    }
.v-dialog .modal figcaption .title {
        margin: 0 5px 0 0;
      }
.v-dialog .modal figcaption p {
        margin: 0;
      }
.v-dialog .modal .body {
      background: #ffffff;
      background: var(--white);
      box-shadow: 0 6px 12px -2px var(--gray-darker);
      padding: 1.5rem;
      border-radius: 3px;
      background: #000000;
      background: var(--black);
      color: #ffffff;
      color: var(--white);
    }
.v-dialog button.close {
    position: fixed;
    right: 1.5rem;
    top: 1.5rem;
    font-size: 2.4rem;
    color: #dcdcdc;
    color: var(--gray);
    padding: 0.25em;
    background: transparent;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    background: #ffffff;
    background: var(--white);
    color: #000000;
    color: var(--black);
    border-radius: 50%
  }
.v-dialog button.close .icon {
      display: block;
    }
.v-dialog button.close:hover {
      color: #696969;
      color: var(--gray-dark);
    }
@media (max-width: 767px) {
    .v-dialog .modal {
      width: 90vw;
    }
}
@media (max-width: 820px) and (orientation: landscape) {
    .v-dialog .modal {
      margin: 2rem 0 0;
      width: 45vw;
    }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-shrink-in-enter-active,
.fade-shrink-in-leave-active {
  transition: all 0.3s ease;
}
.fade-shrink-in-enter,
.fade-shrink-in-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
svg.icon {
  width: 24px;
  height: 24px;
}
header.main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  height: var(--header-height);
  background: #ffffff;
  background: var(--white);
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid var(--gray-light);
  display: flex;
  z-index: 9999
}
header.main section {
    width: 100vw;
  }
header.main section > .contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }
header.main h1 {
    margin: 0;
  }
header.main a.logo {
    padding: 0 25px;
    color: #000000;
    color: var(--black);
    height: 50px;
    display: block
  }
header.main a.logo img {
      height: 100%;
    }
header.main a.logo h1 {
      margin: 0;
    }
header.main .nav-toggle,
  header.main .tickets {
    font-family: 'OCRB', system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
    font-family: var(--font-sans);
    text-transform: uppercase;
    color: #000000;
    color: var(--black);
    font-size: 12px;
    text-decoration: none;
    line-height: 80px;
    line-height: var(--header-height);
    padding: 0 25px;
  }
header.main nav {
    position: absolute;
    top: 80px;
    top: var(--header-height);
    width: 100%;
    background: #696969;
    background: var(--gray-dark);
    z-index: 2000;
    border-bottom: 1px solid #ffffff;
    border-bottom: 1px solid var(--white)
  }
header.main nav ul {
      list-style: none;
      margin: 0;
      padding: 0
    }
header.main nav ul li.active a {
        color: #ffffff;
        color: var(--white);
      }
header.main nav ul a {
        transition: color 0.3s;
        color: #000000;
        color: var(--black);
        text-decoration: none;
        padding: 0 25px;
        display: block
      }
header.main nav ul a::first-letter {
          text-transform: uppercase;
        }
header.main nav ul a:hover {
          color: #ffffff;
          color: var(--white)
        }
header.main nav ul a:hover .icon {
            fill: #ffffff;
            fill: var(--white);
          }
header.main nav {

    --nav-padding: 35px
  }
header.main nav ul.navigation {
      padding: var(--nav-padding) 0 15px
    }
header.main nav ul.navigation a {
        font-size: 28px;
      }
header.main nav ul.languages {
      padding: 0 0 var(--nav-padding)
    }
header.main nav ul.languages a {
        font-size: 21px;
      }
@media (max-width: 820px) and (orientation: landscape) {
  header.main a.logo {
    height: 35px;
    padding: 0 25px 0 0;
  }

  header.main .nav-toggle {
    padding: 0 0 0 25px;
  }

  header.main .tickets {
    padding: 0;
  }
}
@media (max-width: 767px) {
  header.main a.logo {
    height: 35px;
    padding: 0 25px 0 0;
  }

  header.main .nav-toggle {
    padding: 0 0 0 25px;
  }

  header.main .tickets {
    padding: 0;
  }
}
@media (max-width: 320px) {
  header.main .tickets span {
    display: none;
  }
}
@media (max-width: 820px) and (orientation: landscape) {
  header.main nav {
    height: calc(100vh - 80px);
    height: calc(100vh - var(--header-height));
    overflow: auto;
  }
}
.social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex
}
.social a {
    padding: 0 5px;
  }
.about {
  background: #696969;
  background: var(--gray-dark);
  min-height: calc(100vh - 80px);
  min-height: calc(100vh - var(--header-height));
  box-sizing: border-box;
  color: #ffffff;
  color: var(--white);
  padding: 15px 0
}
.about h1 {
    color: #000000;
    color: var(--black);
    margin: 25px 0 5px;
  }
.about a {
    color: #000000;
    color: var(--black);
  }
.about .contain {
    padding: 0 25px;
  }
.contact {
  background: #dcdcdc;
  background: var(--gray);
  min-height: calc(100vh - 80px);
  min-height: calc(100vh - var(--header-height));
  box-sizing: border-box;
  padding: 25px 0
}
.contact h1 {
    color: #000000;
    color: var(--black);
    margin: 0 0 5px;
  }
.contact .contain {
    padding: 0 25px;
  }
.home .header-section {
  padding: 50px 0 50px;
  position: relative
}
.home .header-section .contain {
    position: relative;
  }
.home .header-section .animation {
    width: 100%;
    height: 90%;
    top: 0%;
    right: 0;
    position: absolute
  }
.home .header-section .animation .bg {
      position: absolute;
      width: 90%;
      right: 0;
      top: 0;
    }
.home .header-section .title {
    margin: 0;
    font-size: 200px;
    height: 350px;
    width: 520px;
    line-height: 1;
    font-weight: 100;
    position: relative;
  }
.home .header-section .social {
    position: absolute;
    bottom: 0;
    right: 30px;
  }
@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 770px) {
    .home .header-section .title {
      font-size: 160px;
      width: 415px;
      height: 280px;
    }
}
@media (max-width: 640px) {
    .home .header-section .social {
      position: static;
      margin: 25px 0 -25px;
    }
}
@media (max-width: 767px) {
    .home .header-section .title {
      font-size: 100px;
      width: 265px;
      height: 175px;
    }
      .home .header-section .animation .logo {
        width: 100px;
        height: 110px;
        position: absolute;
        right: 10%;
        top: -5%;
      }
}
@media (max-width: 820px) and (orientation: landscape) {
      .home .header-section .animation .logo {
        width: 130px;
        height: 140px;
        position: absolute;
        right: 30%;
        top: -10%;
      }
}
.home .artists-section header, .home .locations-section header, .home .program-section header {
    text-align: center;
  }
.home .artists-section .title, .home .locations-section .title, .home .program-section .title {
    margin: 0;
    padding: 10px 0 0
  }
.home .artists-section .title:first-letter, .home .locations-section .title:first-letter, .home .program-section .title:first-letter {
      text-transform: uppercase;
    }
.home .artists-section .thumbs figure, .home .locations-section .thumbs figure, .home .program-section .thumbs figure {
    position: relative
  }
.home .artists-section .thumbs figure figcaption, .home .locations-section .thumbs figure figcaption, .home .program-section .thumbs figure figcaption {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 10px 3px
    }
.home .artists-section .thumbs figure figcaption.last, .home .locations-section .thumbs figure figcaption.last, .home .program-section .thumbs figure figcaption.last {
        bottom: auto;
        right: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
@media (max-width: 767px) {
      .home .artists-section .thumbs figure figcaption, .home .locations-section .thumbs figure figcaption, .home .program-section .thumbs figure figcaption {
        font-size: 12px
      }

        .home .artists-section .thumbs figure figcaption .title, .home .locations-section .thumbs figure figcaption .title, .home .program-section .thumbs figure figcaption .title {
          padding: 5px 0 0;
        }

    .home .artists-section .contain, .home .locations-section .contain, .home .program-section .contain {
      padding: 0 5px;
    }
}
.home .artists-section {
  background: #dcdcdc;
  background: var(--gray);
  color: #000000;
  color: var(--black)
}
.home .artists-section .icon {
    color: #ffffff;
    color: var(--white);
  }
.home .locations-section {
  background: #000000;
  background: var(--black);
  color: #ffffff;
  color: var(--white)
}
.home .locations-section figure {
    background: #ffffff;
    background: var(--white);
  }
.home .locations-section .last {
    background: #ffffff;
    background: var(--white);
    color: #000000;
    color: var(--black);
  }
.home .program-section {
  background: #f5f5f5;
  background: var(--gray-light)
}
.home .program-section .thumbs li {
    box-sizing: border-box
  }
.home .program-section .thumbs li a {
      transition: color 0.3s, background 0.3s;
      display: flex;
      flex-direction: column;
      background: #dadada;
      background: var(--gray-medium-light)
    }
.home .program-section .thumbs li a:hover {
        background: #000000;
        background: var(--black);
        color: #ffffff;
        color: var(--white);
      }
.home .program-section .thumbs li figure {
      background: #dadada;
      background: var(--gray-medium-light)
    }
.home .program-section .thumbs li figure h3 {
        margin: 0;
      }
.home .program-section .date {
    --date-size: 130px;

    margin: 25% auto 10%;
    background: #000000;
    background: var(--black);
    width: var(--date-size);
    height: var(--date-size);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #ffffff;
    color: var(--white);
    font-size: 24px;
    text-align: center;
    text-transform: uppercase
  }
.home .program-section .date h3 {
      width: 70%;
      margin: auto;
    }
.home .program-section .title {
    text-align: center;
    width: 75%;
    margin: auto;
  }
.home .program-section .thumbs .title {
    height: 100px;
    font-size: 11px;
    line-height: 1.5;
  }
.home .sponsors-section {
  background: #696969;
  background: var(--gray-dark);
  padding: 15px 0
}
.home .sponsors-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
.home .sponsors-section a {
    display: block;
  }
.home .sponsors-section img {
    width: 100%;
    display: block;
  }
.home .sponsors-section .premium li {
    flex: 0 0 10%;
  }
.home .sponsors-section .regular li {
    flex: 0 0 5%;
  }
.locations.index {
  background: #000000;
  background: var(--black);
  color: #ffffff;
  color: var(--white)
}
.locations.index .map .overview a,
  .locations.index .list a {
    color: #ffffff;
    color: var(--white);
  }
.locations.detail {
  background: #dcdcdc;
  background: var(--gray)
}
.locations.detail header .title {
    margin: 25px 0 10px;
  }
.locations.detail ul.thumbs li {
  flex: 1 0 50%;
}
.locations .events ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
.locations .events li {
    border-bottom: 1px solid #000000;
    border-bottom: 1px solid var(--black);
  }
.locations .events a {
    display: flex;
    align-items: baseline
  }
.locations .events a .title {
      margin-right: 15px;
    }
.locations {
  min-height: calc(100vh - 80px);
  min-height: calc(100vh - var(--header-height))
}
.locations .list .title {
      margin-bottom: 0;
    }
#map {
  min-height: 510px;
  flex: 2
}
#map .leaflet-div-icon {
    background: transparent;
    border: none;
  }
#map .leaflet-marker-icon .number {
    position: relative;
    top: -37px;
    font-size: 12px;
    width: 25px;
    text-align: center;
    font-family: 'OCRB', system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
    font-family: var(--font-sans);
    color: #ffffff;
    color: var(--white);
    font-weight: bold;
  }
#map .leaflet-marker-icon img {
    width: 100%;
    height: 100%;
  }
@media (max-width: 820px) and (orientation: landscape) {
  .locations.index .map {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .locations.index .map {
    flex-direction: column;
  }

  .locations .list {
    margin-top: 0;
    padding-top: 15px
  }

    .locations .list .title {
      margin-top: 0;
    }
}
.photographers {
  background: #f5f5f5;
  background: var(--gray-light);
  min-height: calc(100vh - 80px);
  min-height: calc(100vh - var(--header-height));
  box-sizing: border-box
}
.photographers .url {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
.photographers.overview ul {
    margin: 0 0 0;
    padding: 10px 0 0;
    display: flex;
    flex-wrap: wrap
  }
.photographers.overview ul li {
      flex: 0 0 25%;
      list-style: none
    }
.photographers.overview ul li a {
        margin: 0 1px 0;
        display: block;
        text-decoration: none;
        color: #000000;
        color: var(--black);
        position: relative
      }
.photographers.overview ul li a .icon {
          color: #ffffff;
          color: var(--white);
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
.photographers.overview ul li a:hover img {
            opacity: 0.3;
          }
.photographers.overview ul li a:hover .icon {
            display: block;
          }
.photographers.overview ul li figure {
        background: #000000;
        background: var(--black);
      }
.photographers.overview ul li img {
        width: 100%;
        display: block;
        transition: opacity 0.3s;
      }
.photographers.overview ul li p {
        margin: 5px 0 0;
      }
@media (max-width: 767px) {
  .photographers.overview .contain {
    padding: 0 5px;
  }

  .photographers.overview ul li {
    flex: 0 0 50%;
  }
}
@media (max-width: 820px) and (orientation: landscape) {
  .photographers.overview .contain {
    padding: 0 5px;
  }

  .photographers.overview ul li {
    flex: 0 0 33.3%;
  }
}
.photographers.detail .detail ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
.photographers.detail .detail img {
      width: 100%;
    }
.photographers.detail .detail .work {
      margin: 0 0 8px;
    }
section.program {
  min-height: calc(100vh - 80px);
  min-height: calc(100vh - var(--header-height));
  background: #f5f5f5;
  background: var(--gray-light)
}
section.program *[id]:before {
    display: block;
    content: ' ';
    margin-top: -115px;
    height: 115px;
    visibility: hidden;
  }
section.program ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
section.program .row {
    border-bottom: 1px solid #000000;
    border-bottom: 1px solid var(--black);
    box-sizing: border-box;
  }
section.program .day {
    margin: 0
  }
section.program .day:first-letter {
      text-transform: uppercase;
    }
section.program .events {
    flex: 3;
    padding: 25px;
  }
section.program .events li {
    border-bottom: 1px solid #000000;
    border-bottom: 1px solid var(--black);
    margin-bottom: 15px
  }
section.program .events li:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
section.program .date {
    padding: 25px;
  }
.event .title {
    margin: 0 0 5px;
  }
.event .time {
    margin: 5px 0;
  }
@media (max-width: 767px) {
  section.program .row {
    flex-direction: column;
  }
}
@media (max-width: 820px) and (orientation: landscape) {
  section.program .row {
    flex-direction: row;
  }
}
html {
  font-family: 'Lubalin', serif;
  font-family: var(--font-serif);
  font-size: 62.5%;
}
body {
  font-size: 1.4rem;
  padding-top: 80px;
  padding-top: var(--header-height);
  margin: 0
}
body.modal-open {
    overflow: hidden;
  }
[v-cloak] {
  display: none;
}
